.chat-box {
    position: fixed;
    bottom: 35px;
    right: 35px;
    padding: 10px 10px;
    background-color: rgb(71, 193, 30);
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  